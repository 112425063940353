<template>
  <div class="detail-page-preload">
    <div class="d-flex align-items-center justify-content-between preload-head">
      <div class="title-placeholder"></div>
      <div class="date-placeholder"></div>
    </div>
    <div class="preload-body"></div>
  </div>
</template>

<script>
export default {
  name: "detailPagePreloader"
};
</script>

<style scoped lang="scss">
.detail-page-preload {
  .animation-placeholder {
    position: relative;
    background: linear-gradient(#ebebeb, #b6b6b62b);
    overflow: hidden;
    &:after,
    &:before {
      content: "";
      background: linear-gradient(#ebebeb, #b6b6b62b);
      position: absolute;
      transform: skewX(-22deg);
      width: 30%;
      height: 100%;
      z-index: 10;
      animation: identifier linear 1s infinite;
    }
    &:after {
    }
    &:before {
      animation-delay: 500ms;
    }
  }
  .preload-head {
    margin-bottom: 30px;
    .title-placeholder,
    .date-placeholder {
      @extend .animation-placeholder;
    }

    .title-placeholder {
      height: 50px;
      width: 100%;
      max-width: 1000px;
      margin-right: 60px;
    }

    .date-placeholder {
      height: 20px;
      width: 100px;
    }
  }

  .preload-body {
    @extend .animation-placeholder;
    height: 100%;
    min-height: 300px;
  }
}

@keyframes identifier {
  from {
    transform: skewX(-22deg);
    left: -100%;
  }
  to {
    transform: skewX(-22deg);
    left: 100%;
  }
}
</style>
